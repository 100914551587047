import React from 'react';

import { MobileProvider } from './utils/mobile';
import Wrapper from './Wrapper';

function App (): JSX.Element {
  return (
    <MobileProvider>
      <Wrapper />
    </MobileProvider>
  );
}

export default App;
