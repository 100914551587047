import axios from 'axios';

export interface ImageNode {
  id: string
  src: string
  source: string
  height: number
  width: number
  sort: number
}

const GALLERY_ID = 'DHthczcLDQkOOXgO9OI-o';

const getGalleryHtml = async (): Promise<string> => {
  const html = await axios({
    method: 'get',
    url: `https://fotoshare.co/e/${GALLERY_ID}`
  });

  return html.data.replace(/\s{2,}/g, ' ');
};

const parseImageDataFromGalleryHtml = (html: string): ImageNode[] => {
  const el = document.createElement('html');
  el.innerHTML = html;

  const thumbNodes = Array.from(el.getElementsByClassName('thumb'));

  // @ts-expect-error
  const result = thumbNodes.map((t: HTMLElement): ImageNode => {
    const { dataset } = t;
    const imageNode: ImageNode = {
      id: dataset.hash as string,
      source: dataset.img as string,
      src: dataset.img as string,
      width: parseInt(dataset.width as string, 10),
      height: parseInt(dataset.height as string, 10),
      sort: 0
    };

    const sortNumber = /_(\d*).jpg/.exec(dataset.img as string);

    imageNode.sort = parseInt((sortNumber != null) ? sortNumber[1] : '0', 10);

    return imageNode;
  });

  return result.sort((a, b) => b.sort - a.sort);
};

export {
  getGalleryHtml,
  parseImageDataFromGalleryHtml
};
