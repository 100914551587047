import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
  padding: 12px;
  
  font-family: "Georgia";
  font-style: italic;
`;

const Outline = styled.div`
  border: #383838 4px solid;
  padding: 12px 4px;
`;

const Message = (): JSX.Element => {
  return (
    <Block>
      <Outline>
        Tap a photo strip to view.
      </Outline>
    </Block>
  );
};

export default Message;
