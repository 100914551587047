// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { getGalleryHtml, ImageNode, parseImageDataFromGalleryHtml } from '../utils';
import GalleryComponent from './GalleryComponent';

const PhotoGallery = (): JSX.Element => {
  const [images, setImages] = useState<ImageNode[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((e: unknown, { index }: { index: number }): void => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, [images]);

  const closeLightbox = (): void => {
    setViewerIsOpen(false);
  };

  const handleTrackPropUpdate = (i: number): void => {
    setCurrentImage(i);
  };

  const modal = (
    <Modal onClose={closeLightbox}>
      <Carousel
        currentIndex={currentImage}
        styles={{
          container: (base) => ({ ...base, height: '100vh' }),
          view: (base) => ({
            ...base,
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '5vh',

            '& > img': {
              maxHeight: 'calc(100vh - 94px)',
              maxWidth: '75%',
              borderRadius: '8px',
              marginTop: '12px'
            }
          })
        }}
        views={images}
        trackProps={{
          onViewChange: handleTrackPropUpdate
        }}
      />
    </Modal>
  );

  useEffect(() => {
    getGalleryHtml()
      .then(r => parseImageDataFromGalleryHtml(r))
      .then(i => setImages(i))
      .catch(() => {});
  }, []);

  const gallery = (images.length > 0) ? <GalleryComponent photos={images} openLightbox={openLightbox} /> : null;
  return (
    <div>
      {gallery}
      <ModalGateway>
        {viewerIsOpen ? modal : null}
      </ModalGateway>
    </div>
  );
};

export default PhotoGallery;
