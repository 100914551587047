/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import styled, { css } from 'styled-components';

import { PhotoGallery } from './Gallery';
import Message from './Message';
import { useMobile } from './utils/mobile';

interface WidthProps {
  isMobile: boolean
}

const Container = styled.div`
  text-align: center;
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const ResponsiveWidth = styled.div<WidthProps>`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => css`
    width: ${isMobile ? 100 : 50}%;
  `}
`;

const Wrapper = (): JSX.Element => {
  const { isMobile } = useMobile();

  return (
    <Container>
      <ResponsiveWidth isMobile={isMobile}>
        <Message />
        <PhotoGallery />
      </ResponsiveWidth>
    </Container>
  );
};

export default Wrapper;
