/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import Gallery from 'react-photo-gallery';
import { ImageNode } from '../utils';
import { useMobile } from '../utils/mobile';

interface Props {
  photos: ImageNode[]
  openLightbox: (e: unknown, { index }: { index: number }) => void
}

const GalleryComponent = ({ photos, openLightbox }: Props): JSX.Element => {
  const { isMobilePortrait } = useMobile();
  const initialColumnCount = isMobilePortrait ? 2 : 4;

  const [galleryColumns, setGalleryColumns] = useState(initialColumnCount);

  useEffect(() => {
    const newColumnCount = (isMobilePortrait) ? 2 : 4;
    setGalleryColumns(newColumnCount);
  }, [isMobilePortrait]);

  return (
    <Gallery
      direction='column'
      columns={galleryColumns}
      photos={photos}
      onClick={openLightbox}
    />
  );
};

export default GalleryComponent;
